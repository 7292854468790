<template>
  <div id="PMReportDownload" class="items-center justify-center inset-0 ...">
    <table style="background-color: white" class="text-center w-full m-3">
      <tr class="HeaderTableColor">
        <th class="text-1xl h-25 text-center">
          {{ $t("MedicalDocumentation") }}
        </th>
        <th class="text-1xl h-25 text-center">
          {{ $t("Date") }}
        </th>

        <th class="text-1xl h-25 text-center">
          <div class="MyPopClose">
            <feather-icon
              class="m-2"
              icon="XIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
        </th>
      </tr>
      <tr :data="tr" :key="indextr" v-for="(tr, indextr) in Model">
        <td>
          <p class="product-name font-medium truncate">
            {{ tr.ReportTypeName }}
          </p>
        </td>
        <td>
          <p class="product-name font-medium truncate" type="date">
            {{ tr.Date.split("T")[0] }}
          </p>
        </td>
        {{
          tr.AttachPath
        }}
        <td style="background: primary" v-if="tr.AttachPath">
          <div class="m-3">
            <a :href="baseURL + tr.AttachPath" download target="_blank"  @click="changeDocStatus(tr)"
>
              <vs-avatar
                color="primary"
                icon-pack="feather"
                icon="icon-download"
                style="background: primary"
                class="-m-3"
            /></a>
            <p
              style="
                color: #ffffff;
                font-size: 12px;
                opacity: 1;

                line-height: 30px;
              "
            >
              {{ $t("Download") }}
            </p>
          </div>
        </td>
        <td v-else>
          <p class="product-name font-medium truncate" type="date">
            {{ $t("Pending") }}
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";

export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  props: {
    patientDoc: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        debugger;
        return this.$refs.table.currentx;
      }
      return 0;
    },
    doctorMedical() {
      return this.$store.state.DoctorList.patientReservation;
    },
  },
  methods: {
      changeDocStatus(Doc) {
      debugger
      Doc;
      debugger
      var model = {};
      model.doctorID = Doc.DoctorID;
      model.patientID = Doc.PatientID;
      model.PatientReservationSurgeryID = Doc.PatientReservationSurgeryID;

      model.ID = Doc.ID;
      model.DocumentTypeID = Doc.DocumentTypeID;

      debugger;
      this.$store
        .dispatch("DoctorList/ChangeDocStatus", model)
        .then((res) => {
          if (res.status == 200) {
            
            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    }, 
    searchData() {
      //mkl
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientReservationSurgeryID = this.patientDoc.PatientReservationSurgeryID;

      this.$store
        .dispatch("DoctorList/GetAllPatientMedicalReportDownloadHospitalSurgery", model)
        .then((res) => {
          this.$vs.loading.close();
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    debugger;
    this.searchData();
  },
};
</script>
<style>
#PMReportDownload table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  background: white;
}
#PMReportDownload th,
#PMReportDownload td {
  border: 1px solid #85828220;
  border-collapse: collapse;
  text-align: center;
  font: normal normal normal 20px/28px Futura PT;
  height: 50px;
}
#PMReportDownload .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#PMReportDownload .login-tabs-container {
  min-height: 505px;
}
#PMReportDownload .con-tab {
  padding-bottom: 14px;
}

#PMReportDownload .con-slot-tabs {
  margin-top: 1rem;
}
#PMReportDownload .background {
  color: rgb(255, 9, 9);
}
#PMReportDownload .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PMReportDownload .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #PMReportDownload th,
[dir] #PMReportDownload td {
  border: 1px solid #cfcfcf !important;
}
</style>
