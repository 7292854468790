<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="MyForm w-full">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
            <div class="MyPopClose">
              <feather-icon
                icon="XIcon"
                svgClasses="p-1 w-8 h-8 hover:text-primary stroke-current"
                @click.stop="$emit('closePop')"
              />
            </div>

            <div class="vx-row lg:w-full">
              <div class="vx-col text-center lg:w-1/2 w-full">
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-4"
                  label="Name"
                  v-model="itemModel.DocumentID"
                  :options="DocumentTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('DocumentType')"
                />
              </div>
              <div
                class="vx-col text-center lg:w-1/2 w-full"
                v-if="itemModel.DocumentID == 1"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.ScanTypeID"
                  :options="ScanTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ScanType')"
                />
              </div>
              <div
                class="vx-col lg:w-1/2 w-full text-center"
                v-if="itemModel.DocumentID == 2"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.AnalysisTypeID"
                  :options="AnalysisTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('AnalysisType')"
                />
              </div>
              <div
                class="vx-col text-center lg:w-1/2 w-full"
                v-if="itemModel.DocumentID == 3"
              >
                <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="itemModel.ReportTypeID"
                  :options="ReportTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ReportType')"
                />
              </div>
              <div class="w-full lg:w-full md:w-/3 ml-3">
                <vs-input
                  class="w-full"
                  v-model="itemModel.Description"
                  :placeholder="$t('Description')"
                />
              </div>
            </div>

            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="$emit('AddItem')"
                >{{ $t("Save") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import moduleDoctorRequestType from "@/store/settings/doctorRequestType/moduleDoctorRequestType.js";

export default {
  data() {
    return {};
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  methods: {},
  computed: {
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
    DoctorTypes() {
      return this.$store.state.DoctorRequestTypeList.DoctorRequestTypes;
    },
  },
  created() {
    debugger
    if (!moduleDoctorRequestType.isRegistered) {
      this.$store.registerModule(
        "DoctorRequestTypeList",
        moduleDoctorRequestType
      );
      moduleDoctorRequestType.isRegistered = true;
    }

    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");

    // this.$store.dispatch("DoctorRequestTypeList/GetAllDoctorRequestTypes");
  },
};
</script>

<style lang="scss">
.MyForm {
  width: 50vh;
  margin: auto;
}
</style>
